<template>
  <svg width="124" height="44" viewBox="0 0 124 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.825195" width="122.909" height="43.0556" rx="8.61111" fill="white"/>
    <path d="M11.1582 11.2715V21.7608V32.2501L21.7298 21.7608L11.1582 11.2715Z" fill="url(#paint0_linear_1211_78)"/>
    <path d="M24.5702 18.9433L20.3013 16.4788L11.1582 11.1943V11.2721L21.7298 21.7614L24.5702 18.9433Z" fill="url(#paint1_linear_1211_78)"/>
    <path d="M11.1582 32.25V32.326L20.3013 27.0433L24.5684 24.577L21.7298 21.7607L11.1582 32.25Z" fill="url(#paint2_linear_1211_78)"/>
    <path d="M24.568 18.9424L21.7275 21.7605L24.5661 24.5768L29.4439 21.7605L24.568 18.9424Z" fill="url(#paint3_linear_1211_78)"/>
    <path d="M36.1753 16.8092C36.9495 16.8092 37.6527 16.4616 37.8739 15.8296V16.7144H38.498V14.2575H36.357V14.7947H37.8739C37.787 15.6479 37.1865 16.2325 36.2543 16.2325C35.1799 16.2325 34.4531 15.4662 34.4531 14.2338C34.4531 13.0014 35.172 12.2272 36.1911 12.2272C37.1944 12.2272 37.7791 12.8276 37.8344 13.7361L38.4901 13.6966C38.4032 12.4247 37.5894 11.6426 36.1832 11.6426C34.8086 11.6426 33.75 12.6933 33.75 14.2259C33.75 15.7743 34.7691 16.8092 36.1753 16.8092Z" fill="#222222"/>
    <path d="M42.0094 15.4899C41.8672 16.0192 41.5275 16.2957 40.9508 16.2957C40.3346 16.2957 39.8526 15.8217 39.821 15.1502H42.5703V14.8895C42.5703 13.9494 42.0094 13.1673 40.9113 13.1673C39.9158 13.1673 39.1653 13.9494 39.1653 15.0396C39.1653 16.114 39.9158 16.8092 40.9429 16.8092C41.804 16.8092 42.3807 16.3352 42.5545 15.5531L42.0094 15.4899ZM39.8368 14.7236C39.9158 14.1548 40.2872 13.665 40.9113 13.665C41.567 13.665 41.8751 14.1706 41.8988 14.7236H39.8368Z" fill="#222222"/>
    <path d="M43.3702 15.9086C43.3702 16.5801 43.7178 16.8092 44.2076 16.8092C44.5868 16.8092 44.8396 16.7065 45.0924 16.5169V16.0745C44.8949 16.1851 44.6658 16.2799 44.4446 16.2799C44.1444 16.2799 44.018 16.1377 44.018 15.8296V13.7361H44.9897V13.2621H44.018V12.3062H43.6467L43.544 12.7881C43.465 13.1199 43.3939 13.2779 43.07 13.3411L42.8488 13.3806V13.7361H43.3702V15.9086Z" fill="#222222"/>
    <path d="M47.0861 13.2621V16.7144H47.7418V13.2621H47.0861ZM47.0703 12.48H47.7497V11.7374H47.0703V12.48Z" fill="#222222"/>
    <path d="M48.7553 15.9086C48.7553 16.5801 49.1029 16.8092 49.5927 16.8092C49.9719 16.8092 50.2247 16.7065 50.4775 16.5169V16.0745C50.28 16.1851 50.0509 16.2799 49.8297 16.2799C49.5295 16.2799 49.4031 16.1377 49.4031 15.8296V13.7361H50.3748V13.2621H49.4031V12.3062H49.0318L48.9291 12.7881C48.8501 13.1199 48.779 13.2779 48.4551 13.3411L48.2339 13.3806V13.7361H48.7553V15.9086Z" fill="#222222"/>
    <path d="M52.9214 14.9922C52.9214 14.218 53.3085 13.6808 54.0037 13.6808C54.7068 13.6808 55.1018 14.218 55.1018 14.9922C55.1018 15.7585 54.7068 16.3036 54.0037 16.3036C53.3085 16.3036 52.9214 15.7585 52.9214 14.9922ZM55.7733 14.9922C55.7733 13.9178 55.0228 13.1673 54.0037 13.1673C52.9925 13.1673 52.2499 13.9178 52.2499 14.9922C52.2499 16.0666 52.9925 16.8092 54.0037 16.8092C55.0228 16.8092 55.7733 16.0666 55.7733 14.9922Z" fill="#222222"/>
    <path d="M57.1077 14.7315C57.1077 14.0995 57.5185 13.6887 58.0399 13.6887C58.585 13.6887 58.8615 14.0521 58.8615 14.6288V16.7144H59.5172V14.5735C59.5172 13.7677 59.1538 13.1673 58.2532 13.1673C57.7397 13.1673 57.2736 13.4596 57.1077 13.8783V13.2621H56.452V16.7144H57.1077V14.7315Z" fill="#222222"/>
    <path d="M38.5938 29.3142C39.9234 29.3142 41.0631 28.871 41.6013 27.8738V29.1243H43.7066V24.0114H39.0528V25.7527H41.443C41.0948 26.7341 40.1608 27.2881 39.0686 27.2881C37.2483 27.2881 36.2194 26.0376 36.2194 24.1381C36.2194 22.2861 37.2166 21.083 38.8787 21.083C40.3191 21.083 41.1739 21.8903 41.3006 23.1408L43.6433 23.0617C43.4217 20.5448 41.7279 18.9619 38.8312 18.9619C35.9819 18.9619 33.75 21.0672 33.75 24.1381C33.75 27.2248 35.8236 29.3142 38.5938 29.3142Z" fill="#222222"/>
    <path d="M47.0824 25.6102C47.0824 24.4388 47.5415 23.6315 48.5546 23.6315C49.5518 23.6315 50.0425 24.4388 50.0425 25.6102C50.0425 26.7816 49.5518 27.5889 48.5546 27.5889C47.5415 27.5889 47.0824 26.7816 47.0824 25.6102ZM52.3536 25.6102C52.3536 23.4416 50.8023 21.9062 48.5546 21.9062C46.3068 21.9062 44.7714 23.4416 44.7714 25.6102C44.7714 27.7946 46.3068 29.3142 48.5546 29.3142C50.8023 29.3142 52.3536 27.7946 52.3536 25.6102Z" fill="#222222"/>
    <path d="M55.4144 25.6102C55.4144 24.4388 55.8735 23.6315 56.8865 23.6315C57.8838 23.6315 58.3745 24.4388 58.3745 25.6102C58.3745 26.7816 57.8838 27.5889 56.8865 27.5889C55.8735 27.5889 55.4144 26.7816 55.4144 25.6102ZM60.6856 25.6102C60.6856 23.4416 59.1343 21.9062 56.8865 21.9062C54.6388 21.9062 53.1034 23.4416 53.1034 25.6102C53.1034 27.7946 54.6388 29.3142 56.8865 29.3142C59.1343 29.3142 60.6856 27.7946 60.6856 25.6102Z" fill="#222222"/>
    <path d="M68.243 24.3122C68.243 23.9481 68.1481 23.6315 67.9898 23.3466C68.338 23.2516 68.7496 23.2042 69.082 23.2042V21.4471C68.3222 21.4471 67.5782 21.922 67.1667 22.4918C66.581 22.1119 65.7737 21.9062 64.9189 21.9062C62.9877 21.9062 61.6106 22.8243 61.6106 24.3122C61.6106 25.072 61.9747 25.6893 62.6078 26.1009C61.8797 26.37 61.4365 26.8924 61.4365 27.5414C61.4365 28.1429 61.8005 28.6811 62.4337 28.8869C61.6264 28.966 61.0724 29.4725 61.0724 30.1215C61.0724 31.4354 62.7661 31.8786 64.7448 31.8786C66.9925 31.8786 69.082 31.1029 69.082 29.3459C69.082 28.1904 68.2747 27.3514 66.7076 27.3514H63.8267C63.3676 27.3514 63.1777 27.1456 63.1777 26.8765C63.1777 26.7024 63.2568 26.5758 63.3676 26.4808C63.8267 26.6233 64.349 26.7024 64.9189 26.7024C66.8501 26.7024 68.243 25.7843 68.243 24.3122ZM63.0194 29.8524C63.0194 29.4884 63.3043 29.3142 63.6684 29.3142H66.1536C66.6759 29.3142 66.9609 29.4409 66.9609 29.8208C66.9609 30.3431 66.0269 30.6122 64.7606 30.6122C63.7317 30.6122 63.0194 30.4065 63.0194 29.8524ZM65.8845 24.3122C65.8845 25.0245 65.4888 25.4361 64.9347 25.4361C64.3965 25.4361 64.0008 25.0245 64.0008 24.3122C64.0008 23.6157 64.3965 23.22 64.9347 23.22C65.4888 23.22 65.8845 23.6157 65.8845 24.3122Z" fill="#222222"/>
    <path d="M69.8385 19.1519V29.1243H72.1179V19.1519H69.8385Z" fill="#222222"/>
    <path d="M78.644 26.6391C78.3907 27.2881 77.9 27.5889 77.1085 27.5889C76.2221 27.5889 75.5256 27.0507 75.4623 26.1167H80.5277V25.4202C80.5277 23.5049 79.3088 21.9062 76.9819 21.9062C74.8291 21.9062 73.2145 23.5049 73.2145 25.721C73.2145 27.9529 74.7975 29.3142 77.0294 29.3142C78.8656 29.3142 80.1161 28.412 80.4802 26.8132L78.644 26.6391ZM75.494 24.8979C75.6206 24.1539 76.048 23.5682 76.9661 23.5682C77.8367 23.5682 78.2799 24.2014 78.3274 24.8979H75.494Z" fill="#222222"/>
    <path d="M86.9464 23.9481V21.083H88.6718C89.9856 21.083 90.5238 21.5896 90.5238 22.5235C90.5238 23.4574 89.9856 23.9481 88.6718 23.9481H86.9464ZM86.9464 25.8793H88.7668C91.3469 25.8793 92.8507 24.6604 92.8507 22.5077C92.8507 20.3549 91.3469 19.1519 88.7668 19.1519H84.5562V29.1243H86.9464V25.8793Z" fill="#222222"/>
    <path d="M93.7524 19.1519V29.1243H96.0318V19.1519H93.7524Z" fill="#222222"/>
    <path d="M101.656 26.1484C101.656 27.1456 100.991 27.7788 100.152 27.7788C99.6611 27.7788 99.3445 27.5255 99.3445 27.114C99.3445 26.56 99.7878 26.3858 100.5 26.2434L101.656 26.0059V26.1484ZM103.903 24.6446C103.903 22.7293 102.59 21.9062 100.753 21.9062C98.743 21.9062 97.6192 22.9509 97.3817 24.4547L99.5028 24.613C99.6295 24.0273 99.9619 23.6157 100.627 23.6157C101.323 23.6157 101.656 24.0114 101.656 24.6763V24.8029L99.6928 25.1511C98.1415 25.4519 97.1285 26.0218 97.1285 27.3672C97.1285 28.5703 98.0149 29.2826 99.4237 29.2826C100.516 29.2826 101.387 28.8552 101.782 28.0479C101.988 28.8394 102.605 29.2826 103.444 29.2826C104.141 29.2826 104.584 29.0768 104.932 28.8235V27.6047C104.726 27.6997 104.552 27.7313 104.347 27.7313C104.062 27.7313 103.903 27.573 103.903 27.209V24.6446Z" fill="#222222"/>
    <path d="M108.717 31.6886L112.754 22.0961H110.506L108.781 26.4808H108.717L107.024 22.0961H104.618L107.498 29.1876L106.47 31.6886H108.717Z" fill="#222222"/>
    <defs>
      <linearGradient id="paint0_linear_1211_78" x1="16.4436" y1="11.2706" x2="16.4436" y2="32.2497" gradientUnits="userSpaceOnUse">
        <stop stop-color="#257CB0"/>
        <stop offset="0.2863" stop-color="#4A93B5"/>
        <stop offset="0.767" stop-color="#78BCBB"/>
        <stop offset="1" stop-color="#89CFBD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1211_78" x1="11.1574" y1="16.4779" x2="24.5694" y2="16.4779" gradientUnits="userSpaceOnUse">
        <stop stop-color="#52C1AD"/>
        <stop offset="1" stop-color="#DEE89A"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1211_78" x1="17.8633" y1="21.7603" x2="17.8633" y2="32.326" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EC413D"/>
        <stop offset="0.1668" stop-color="#DA4452"/>
        <stop offset="0.5748" stop-color="#B0487A"/>
        <stop offset="0.8619" stop-color="#954A92"/>
        <stop offset="1" stop-color="#8A4A9D"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1211_78" x1="25.5858" y1="18.9425" x2="25.5858" y2="24.5772" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F58879"/>
        <stop offset="0.1194" stop-color="#F69079"/>
        <stop offset="0.7128" stop-color="#FCB877"/>
        <stop offset="1" stop-color="#FEC874"/>
      </linearGradient>
    </defs>
  </svg>
</template>